<template>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >Configuraciones</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3">Usuarios</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <Toast />
    <div
      class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
    >
      <div>
        <div class="font-medium text-3xl text-900">
          Administrar Usuarios
          <Badge size="xlarge" severity="success"> {{ totalRegistros }}</Badge>
        </div>
      </div>
      <div class="mt-3 lg:mt-0">
        <Button
          v-if="'Usuario Crear' in auth.user.permissions"
          label="NUEVO USUARIO"
          class="p-button-outlined mr-2"
          icon="pi pi-plus"
          v-tooltip.top="'Nuevo Usuario'"
          @click="openNuevo"
        ></Button>
      </div>
    </div>
    <div class="mt-4">
      <DataTable
        ref="dtusers"
        :value="users"
        :loading="cargando"
        dataKey="id"
        :paginator="true"
        :rows="10"
        :filters="buscar"
        class="p-datatable-sm"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 20, 50]"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Useres"
        responsiveLayout="scroll"
        showGridlines
        selectionMode="single"
        @row-dblclick="editUser($event.data)"
      >
        <template #header>
          <div
            class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
          >
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="buscar['global'].value"
                placeholder="Buscar..."
              />
            </span>
          </div>
        </template>

        <template #loading>
          Se estan buscando los Usuarios, por favor espere!
        </template>

        <template #empty> No existen Usuarios! </template>

        <Column field="id" header="CÓDIGO" :sortable="true">
          {{ data.id }}
        </Column>
        <Column field="name" header="NOMBRE">
          {{ data.name }}
        </Column>
        <Column field="email" header="CORREO ELECTRÓNICO">
          {{ data.email }}
        </Column>
        <Column field="roles" header="ROLES DE USUARIO">
          <template #body="{ data }">
            <span
              class="product-badge bg-blue-400 mr-1 text-white"
              v-for="(rol, index) in data.roles"
              :key="index"
            >
              {{ rol }}
            </span>
          </template>
        </Column>
        <Column field="created_at" header="FECHA DE CREACIÓN">
          <template #body="{ data }">
            {{ formatDate(data.created_at) }}
          </template>
        </Column>
        <Column field="updated_at" header="ÚLTIMA MODIFICACIÓN">
          <template #body="{ data }">
            {{ formatDate(data.updated_at) }}
          </template>
          |
        </Column>
        <Column headerStyle="min-width:10rem;" header="ACCIONES">
          <template #body="slotProps">
            <Button
              class="p-button-icon-only p-button-raised"
              icon="pi pi-ellipsis-v"
              v-tooltip.top="'Ver Acciones'"
              @click="$refs['menu' + slotProps.data.id].toggle($event)"
            />
            <Menu
              :ref="'menu' + slotProps.data.id"
              :popup="true"
              :model="acciones(slotProps.data)"
            >
            </Menu>
          </template>
        </Column>
      </DataTable>
    </div>

    <Dialog
      v-model:visible="userDialog"
      :style="{ width: '450px' }"
      header="Usuario Detalle"
      :modal="true"
      class="p-fluid"
    >
      <div class="field">
        <label for="name"
          ><strong>NOMBRE COMPLETO: </strong>
          <span class="p-invalid">*</span></label
        >
        <InputText
          id="name"
          v-model.trim="user.name"
          required="true"
          autofocus
        />
        <small class="p-invalid" v-if="errors.name">{{ errors.name[0] }}</small>
      </div>
      <div class="field">
        <label for="email"
          ><strong>CORREO ELECTR&Oacute;NICO: </strong>
          <span class="p-invalid">*</span></label
        >
        <InputText id="email" v-model="user.email" required="true" />
        <small class="p-invalid" v-if="errors.email">{{
          errors.email[0]
        }}</small>
      </div>
      <div class="field">
        <label for="password"
          ><strong>CONTRASEÑA: </strong> <span class="p-invalid">*</span></label
        >
        <InputText id="password" v-model="user.password" />
        <small class="p-invalid" v-if="errors.password">{{
          errors.password[0]
        }}</small>
      </div>
      <div class="field">
        <label for="rol"
          ><strong>ROL DE USUARIO: </strong>
          <span class="p-invalid">*</span></label
        >
        <MultiSelect
          id="roles"
          v-model="user.roles"
          :options="roles"
          optionLabel="name"
          placeholder="Seleccione Roles"
          display="chip"
        />
        <small class="p-invalid" v-if="!user.roles">Se necesita rol.</small>
      </div>
      <template #footer>
        <div class="field">
          <label class="flex md:justify-content-start"
            ><strong>CAMPOS REQUERIDOS: </strong>
            <span class="p-invalid">*</span></label
          >
        </div>
        <Button
          label="CANCELAR"
          icon="pi pi-times"
          class="p-button-secondary p-button-lg"
          @click="ocultarDialog"
          :disabled="!enviado ? false : true"
        />
        <Button
          :label="user.id ? 'ACTUALIZAR' : 'GUARDAR'"
          :icon="user.id ? 'pi pi-pencil' : 'pi pi-save'"
          class="p-button-primary p-button-lg"
          @click="guardarUser"
          :disabled="!enviado ? false : true"
          :loading="enviado"
        />
      </template>
    </Dialog>

    <Dialog
      v-model:visible="deleteUserDialog"
      :style="{ width: '450px' }"
      header="Confirmar"
      :modal="true"
    >
      <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="user"
          >Estas Seguro que quieres eliminar Usuario
          <b>{{ user.name }}</b> ?</span
        >
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-secondary"
          @click="deleteUserDialog = false"
        />
        <Button
          label="Si"
          icon="pi pi-check"
          class="p-button-primary"
          @click="deleteUser"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import UserService from "@/service/UserService";
import RolService from "@/service/RolService";
import { useAuth } from "@/stores";
import { object } from "yup";

export default {
  data() {
    return {
      users: null,
      cargando: true,
      userDialog: false,
      deleteUserDialog: false,
      user: {},
      buscar: {},
      enviado: false,
      roles: {},
      errors: {},
      totalRegistros: 0,
    };
  },
  userService: null,
  rolService: null,
  auth: null,
  created() {
    this.auth = useAuth();
    this.userService = new UserService();
    this.rolService = new RolService();
    this.initBuscar();
  },
  mounted() {
    this.cargarUsuarios();

    this.rolService.getRolesAll().then((roles) => {
      this.roles = roles;
    });
  },
  methods: {
    cargarUsuarios() {
      this.userService.getUsersAll().then((data) => {
        this.users = data;
        this.cargando = false;
        this.users.forEach((user) => {
          let rolesName = new Array();
          user.roles.forEach((rol) => {
            rolesName.push(rol.name.toLowerCase());
          });
          user.roles = rolesName;
          user.created_at = new Date(user.created_at);
          user.updated_at = new Date(user.updated_at);
        });
      });
    },
    acciones(datos) {
      let permisosUsuarioAuth = this.auth.user.permissions;
      return [
        {
          label: "Editar",
          disabled: "Usuario Editar" in permisosUsuarioAuth ? false : true,
          icon: "pi pi-pencil",
          command: () => {
            this.editUser(datos);
          },
        },
        {
          label: "Eliminar",
          disabled: "Usuario Eliminar" in permisosUsuarioAuth ? false : true,
          icon: "pi pi-trash",
          command: () => {
            this.confirmDeleteUser(datos);
          },
        },
      ];
    },
    openNuevo() {
      this.user = {};
      this.enviado = false;
      this.userDialog = true;
    },
    ocultarDialog() {
      this.errors = {};
      this.userDialog = false;
      this.enviado = false;
    },
    guardarUser() {
      this.errors = {};
      this.enviado = true;
      if (this.user.id) {
        //editar Usuario

        let user_enviar = {
          ...this.user,
        };

        this.userService.updatedUser(user_enviar).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
          } else {
            this.errors = {};

            let aux = { ...data.user };
            let rolesName = new Array();
            aux.roles.forEach((rol) => {
              rolesName.push(rol.name.toLowerCase());
            });
            aux.roles = rolesName;

            aux.created_at = new Date(aux.created_at);
            aux.updated_at = new Date(aux.updated_at);

            this.users[this.findIndexById(aux.id)] = aux;

            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: "Usuario Actualizado",
              life: 3000,
            });
            this.ocultarModalUser();
          }
          this.enviado = false;
        });
      } else {
        //Creando Nuevo Usuario

        this.userService.sendUserNuevo(this.user).then((user) => {
          if (object.prototype.hasOwnProperty.call(user, "errors")) {
            this.errors = user.errors;
          } else {
            this.errors = {};
            let rolesName = new Array();
            user.roles.forEach((rol) => {
              rolesName.push(rol.name.toLowerCase());
            });
            user.roles = rolesName;
            user.created_at = new Date(user.created_at);
            user.updated_at = new Date(user.updated_at);
            this.users.push(user);
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: "Usuario Creado",
              life: 10000,
            });
            this.ocultarModalUser();
          }
          this.enviado = false;
        });
      }
    },
    ocultarModalUser() {
      this.userDialog = false;
      this.user = {};
    },
    editUser(user) {
      this.user = { ...user };
      this.user.roles = this.roles.filter((rol) => {
        return user.roles.includes(rol.name.toLowerCase());
      });
      this.userDialog = true;
    },
    confirmDeleteUser(user) {
      this.user = { ...user };
      this.deleteUserDialog = true;
    },
    deleteUser() {
      this.userService.deleteUser(this.user).then((data) => {
        if (data.status == 200) {
          this.$toast.add({
            severity: "success",
            summary: "Exito!",
            detail: data.mensaje,
            life: 3000,
          });
          this.cargarUsuarios();
          this.deleteUserDialog = false;
          this.user = {};
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error!",
            detail: data.mensaje,
            life: 3000,
          });
          this.deleteUserDialog = false;
        }
      });
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.users.length; i++) {
        if (this.users[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    initBuscar() {
      this.buscar = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    /* verDetalle(user){
				this.$router.push(`/user/${user.id}/detalle`); 
		}, */
    formatDate(value) {
      return value.toLocaleDateString("es-ES", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
    },
  },
  watch: {
    users() {
      this.totalRegistros = this.users.length;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>